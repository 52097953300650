import React, { useState, useEffect, useRef } from "react";
import brand1 from "../../Assests/imgs/cta/cta-4/instacart.png";
import brand2 from "../../Assests/imgs/cta/cta-4/goPuff.png";
import brand3 from "../../Assests/imgs/cta/cta-4/doordash.png";
import brand4 from "../../Assests/imgs/cta/cta-4/amazonflex.png";
import brand5 from "../../Assests/imgs/cta/cta-4/uber.png";
import brand6 from "../../Assests/imgs/cta/cta-4/lyft.png";

export default function AboutBenefits() {
  const originalBrandLogos = [brand1, brand2, brand3, brand4, brand5, brand6];
  const brandLogos = [...originalBrandLogos, ...originalBrandLogos]; // Duplicate for smooth looping

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const carouselRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      handleSwipeLeft();
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const handleSwipeLeft = () => {
    setIsTransitioning(true);
    setCurrentIndex((prevIndex) => {
      return prevIndex + 1;
    });
  };

  useEffect(() => {
    if (currentIndex >= originalBrandLogos.length) {
      setTimeout(() => {
        setIsTransitioning(false);
        setCurrentIndex(0);
      }, 500);
    }
  }, [currentIndex]);
  const brandStyles = [
    { maxWidth: "300px", maxHeight: "auto" },
    { maxWidth: "300px", maxHeight: "auto" },
    { maxWidth: "300px", maxHeight: "auto" },
    { maxWidth: "300px", maxHeight: "auto" },
    { maxWidth: "300px", maxHeight: "auto" },
    { maxWidth: "300px", maxHeight: "auto" },
  ];

  const styles = {
    carouselContainer: {
      position: "relative",
      width: "100%",
      maxWidth: "1200px",
      margin: "0 auto",
      overflow: "hidden",
    },
    carouselTrack: {
      display: "flex",
      transition: isTransitioning ? "transform 0.5s ease" : "none",
      transform: `translateX(-${currentIndex * 25}%)`,
    },
    carouselItem: {
      flex: "0 0 33%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "10px",
      boxSizing: "border-box",
    },
    image: {
      maxWidth: "100%",
      maxHeight: "80px",
      objectFit: "contain",
    },
  };

  return (
    <section className="section-cta-4 position-relative overflow-hidden">
      <div className="container position-relative z-1">
        <div className="text-center">
          <h4 className="mt-4 wow fadeInUp">
            Get on the Road with a Rideshare-Ready Car
          </h4>
          <span
            className="text-sm  p-3 rounded-12 wow fadeInDown"
            style={{ color: "#737373", fontSize: "20px" }}
          >
            Affordable Rentals Starting at $55/Day or $350/Week
          </span>
        </div>
        <div className="row mt-60">
          <div style={styles.carouselContainer}>
            <div ref={carouselRef} style={styles.carouselTrack}>
              {brandLogos.map((logo, index) => (
                <div key={index} style={styles.carouselItem}>
                  <img
                    src={logo}
                    className="brandstylesClas"
                    alt={`Brand logo ${index + 1}`}
                    style={{ ...brandStyles[index % brandStyles.length] }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
