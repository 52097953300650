import React, { useEffect, useState } from "react";
import Header2 from "../Sections/Header";
import Offcanvas from "../Elements/Offcanvas";
import BackToTop from "../Elements/BackToTop";
import MobileMenu from "../Elements/MobileMenu";
import Footer from "../Sections/Footer";
import { useLocation } from "react-router-dom";

export default function Layout({ breadcrumbTitle, children }) {
  const location = useLocation();
  const [scroll, setScroll] = useState(false);
  const [isMobileMenu, setMobileMenu] = useState(false);
  const [isOffcanvas, setOffcanvas] = useState(false);

  const handleMobileMenu = () => {
    setMobileMenu(!isMobileMenu);
    !isMobileMenu
      ? document.body.classList.add("mobile-menu-active")
      : document.body.classList.remove("mobile-menu-active");
  };

  const handleOffcanvas = () => {
    setOffcanvas(!isOffcanvas);
    !isOffcanvas
      ? document.body.classList.add("canvas-menu-active")
      : document.body.classList.remove("canvas-menu-active");
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollCheck = window.scrollY > 100;
      if (scrollCheck !== scroll) {
        setScroll(scrollCheck);
      }
    };

    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, [scroll]);

  return (
    <>
      <div id="top" />
      <Header2
        scroll={scroll}
        isMobileMenu={isMobileMenu}
        handleMobileMenu={handleMobileMenu}
        isOffcanvas={isOffcanvas}
        handleOffcanvas={handleOffcanvas}
      />
      {/* <MobileMenu
        isMobileMenu={isMobileMenu}
        handleMobileMenu={handleMobileMenu}
      /> */}

      <Offcanvas
        isMobileMenu={isMobileMenu}
        isOffcanvas={isOffcanvas}
        handleOffcanvas={handleOffcanvas}
      />

      <main className="main">
        {/* {breadcrumbTitle && <Breadcrumb breadcrumbTitle={breadcrumbTitle} />} */}
        {children}
      </main>

      {location.pathname !== "/reset_password" && (
        <>{!location.pathname.includes("/payment_link") && <Footer />}</>
      )}
      <BackToTop target="#top" />
    </>
  );
}
