import { Link } from "react-router-dom";
import Truck from "../../Assests/imgs/app/app-1/Good.png";
import Android from "../../Assests/imgs/template/googleplay.png";
import IOS from "../../Assests/imgs/template/appstore.png";

export default function App() {
  return (
    <>
      <section className="box-app position-relative">
        <div className="container position-relative z-1">
          <div className="row align-items-center py-5">
            <div className="col-lg-5">
              <h4 className="wow fadeInDown">Rapid Cars App – Coming Soon!</h4>
              <p className="text-md-medium pb-3 wow fadeInUp">
                A seamless way to manage your car lease, payments, and
                maintenance—all in one app. Stay tuned for the launch!
              </p>
              <div className="download-apps mt-0">
                <Link className="wow fadeInUp" href="#">
                  <img src={Android} alt="Rapid Cars Google Play" />
                </Link>
                {/* <Link className="wow fadeInUp" data-wow-delay="0.2s" href="#">
                  <img src={IOS} alt="Rapid Cars App Store" />
                </Link> */}
              </div>
            </div>
            <div className="col-lg-7">
              <div className="box-app-img wow fadeIn">
                <img src={Truck} alt="Rapid Cars App" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
