import React, { useEffect, useState } from "react";
import Layout from "../Layout/Layout";
import Hero from "../Sections/Hero";
import CarsListing from "../Sections/CarListing";
import HowItWorks from "../Sections/HowItWorks";
import AboutBenefits from "../Sections/AboutBenefits";
import BrowesTypes from "../Sections/BrowesTypes";
import Testimonials from "../Sections/Testimonials";
import Blog from "../Sections/Blogs";
import ContactForm from "../Sections/ContactForm";
import App from "../Elements/AppDownload";
import Loader from "../Elements/Loader";
import CookieConsent from "../Elements/CookieConsent";
import FeaturesSection from "../Sections/FeaturesSection";

const Home = () => {
  const [loading, setLoading] = useState(true);

  const [selectedCataid, setselectedCataid] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const handleCookieAccept = (preferences) => {
    console.log("Cookies accepted:", preferences);
    // Here you can initialize your analytics, marketing scripts, etc.
  };

  const handleCookieDecline = (preferences) => {
    console.log("Cookies declined:", preferences);
    // Disable non-essential cookies/scripts
  };

  const handleCookieCustomize = (preferences) => {
    console.log("Cookie preferences customized:", preferences);
    // Apply custom cookie preferences
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Layout>
          <Hero />
          <AboutBenefits />
          <HowItWorks />
          <FeaturesSection />
          <CarsListing />
          <BrowesTypes />
          <Testimonials />
          <Blog />
          <ContactForm />
          <App />
        </Layout>
      )}
      <CookieConsent
        position="bottom"
        buttonText="Accept All"
        customizeButtonText="Customize"
        declineButtonText="Decline All"
        cookieName="car-site-cookie-consent"
        expires={365}
        onAccept={handleCookieAccept}
        onDecline={handleCookieDecline}
        onCustomize={handleCookieCustomize}
      />
    </>
  );
};

export default Home;
