import { Link, useNavigate } from "react-router-dom";
import Layout from "../Layout/Layout";
import { useState, useEffect } from "react";
export default function PaymentPage() {
  const navigate = useNavigate();
  const [showLoaderAdmin, setShowLoaderAdmin] = useState(false);
  return (
    <>
      <Layout></Layout>
    </>
  );
}
