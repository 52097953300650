import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ScrollToTop from "./CommonJquery/ScrollToTop";
import "swiper/swiper-bundle.css";
import "../src/Assests/css/main.css";
import "./App.css";
import About from "./Components/Pages/About";
import AplicationForm from "./Components/Pages/AplicationForm";
import BlogDetails from "./Components/Pages/BlogDetails";
import Blogs from "./Components/Pages/Blogs";
import CarDetail from "./Components/Pages/CarDetails";
import Contact from "./Components/Pages/Contact";
import Favorites from "./Components/Pages/Favorites";
import Home from "./Components/Pages/Home";
import Listing from "./Components/Pages/Listing";
import Login from "./Components/Pages/Login";
import PrivacyPolicy from "./Components/Pages/PrivacyPolicy";
import Profile from "./Components/Pages/Profile";
import ResetPassword from "./Components/Pages/ResetPassword";
import Services from "./Components/Pages/Services";
import TermsOfUse from "./Components/Pages/TermsOfUse";
import MyBookings from "./Components/Pages/MyBookings";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { HistoryProvider } from "./Components/Sections/HistoryContext";
import Maintenance from "./Components/Pages/Maintenance";
import Agreement from "./Components/Pages/Agreement";
import StripeCheckout from "./Components/Pages/StripeCheckout";
import PaymentPage from "./Components/Pages/paymnet";

function App() {
  return (
    <BrowserRouter>
      {" "}
      <ScrollToTop />{" "}
      <HistoryProvider>
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="/" element={<Loader />} /> */}
          <Route
            path="/login"
            element={
              <GoogleOAuthProvider clientId="166804490112-bvhkf2hulsrea4499d7pf5jgh2sjnchm.apps.googleusercontent.com">
                <Login />
              </GoogleOAuthProvider>
            }
          />
          <Route path="/Profile" element={<Profile />} />
          <Route path="/reset_password/:id" element={<ResetPassword />} />
          {/* <Route path="/reset_password" element={<ResetPassword />} /> */}
          <Route path="/Listing" element={<Listing />} />
          <Route path="/cars/car_details/:id" element={<CarDetail />} />
          <Route path="/my_bookings" element={<MyBookings />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/Blogs/blogdetail/:id" element={<BlogDetails />} />
          <Route path="/favourite" element={<Favorites />} />
          <Route path="/terms_of_use" element={<TermsOfUse />} />
          <Route path="/privacy_policy" element={<PrivacyPolicy />} />
          <Route path="/application_form" element={<AplicationForm />} />
          <Route path="/maintenance" element={<Maintenance />} />
          <Route path="/agreement" element={<Agreement />} />
          <Route path="/stripe_checkout" element={<StripeCheckout />} />

          <Route path="/payment_link" element={<PaymentPage />} />
        </Routes>{" "}
      </HistoryProvider>
    </BrowserRouter>
  );
}

export default App;
